<template>
  <div
    v-if="list.estimation"
    class="result"
  >
    <b-card
      border-variant="secondary"
      text-variant="white"
      bg-variant="transparent"
      no-body
      style="margin-top:20px"
    >
      <b-card-body>
        <b-alert
          v-if="list.note"
          class="mb-2"
          variant="success"
          show
        >
          <div class="alert-body">
            <span>{{ list.note }}</span>
          </div>
        </b-alert>
        <div class="result-title black-tx">
          <div class="d-flex justify-start align-center result-title-name">
            <span>{{ list.name }}</span>
            <span
              v-if="idx === 0"
              class="logistics-tag"
            >{{ $t('calculate.cheapest') }}</span>
            <span
              v-if="list.id_card === 1"
              class="logistics-tag error-tag"
            >{{ $t('calculate.idCard') }}</span>
          </div>
          <span
            v-if="!list.unableSelect"
            class="price"
          >{{ $t('calculate.freight') }}：{{ currentCurrency.symbol }}{{ $money.format(Number(totalEstimation).toFixed(2)) }}
            <feather-icon
              icon="InfoIcon"
              class="mr-25 info-icon"
              @click="handleInfoIconTotal(list)"
            />
          </span>
          <span
            v-else
            class="price red"
          >{{ $t('calculate.notUse') }}
            <feather-icon
              icon="InfoIcon"
              class="mr-25 info-icon red-icon"
              @click="handleNotUseIcon"
            />
          </span>
        </div>
      </b-card-body>
      <b-card-footer>
        <b-row style="margin:0">
          <b-col
            xl="2"
            lg="3"
            md="4"
            sm="12"
          >
            <div class="black-tx logistics">
              <img
                class="logistics-logo"
                :src="list.logo.indexOf('.') > -1 ? list.logo : require('../assets/images/logo/default.png')"
                alt="logistics-logo"
              >
              <div
                v-show="list.transport_type"
                class="transport"
              >
                <span>{{ list.transport_type }}</span>
                <span class="mt-1">{{ list.pickup_type }}</span>
              </div>
              <span
                v-if="list.link"
                class="line-intro"
                :class="idx === 0 ? 'mt-40' : ''"
                @click="handleLink"
              >{{ $t('calculate.lineIntro') }}</span>
            </div>
          </b-col>
          <b-col
            xl="5"
            lg="9"
            md="8"
            sm="12"
          >
            <div class="black-tx">
              <ul class="logistics-ul">
                <li v-if="list.dispatch_date">
                  <span>{{ $t('calculate.sendDate') }}:</span>
                  <span>{{ list.dispatch_date }}</span>
                </li>
                <li v-if="list.duration">
                  <span>{{ $t('calculate.aging') }}:</span>
                  <span>{{ list.duration }}</span>
                </li>
                <li>
                  <span>{{ $t('calculate.formulaMode') }}:</span>
                  <span>{{ list.weight_calc_type_text }}</span>
                </li>
                <li>
                  <span>{{ $t('calculate.tax') }}:</span>
                  <span>
                    <div>
                      <span class="tax-note">{{ list.include_tax_note }}</span>
                      <feather-icon
                        icon="InfoIcon"
                        class="ml-25 info-icon"
                        @click="handleInfoIcon(list.include_tax_desc)"
                      />
                      <span class="tax-note" v-if="(list.estimated_import_duty + list.estimated_import_tax) > 0"> - {{list.estimated_import_duty_tax_description}}</span>
                    </div>
                  </span>
                </li>
                <li v-if="Number(list.tax_fee) > 0">
                  <span>{{ $t('calculate.taxFee') }}:</span>
                  <span>{{ currentCurrency.symbol }}{{ $money.format(Number(list.tax_fee).toFixed(2)) }}
                    <feather-icon
                      v-if="list.tax_fee_detail && list.tax_fee_detail.length > 0"
                      icon="InfoIcon"
                      class="mr-25 info-icon"
                      @click="handleInfoIconE('tax', list.tax_fee_detail)"
                    />
                  </span>
                </li>
                <li v-if="Number(list.extra_fee) > 0">
                  <span>{{ $t('calculate.extraFee') }}:</span>
                  <span>{{ currentCurrency.symbol }}{{ $money.format(Number(list.extra_fee).toFixed(2)) }}
                    <feather-icon
                      v-if="list.extra_fee_detail && list.extra_fee_detail.length > 0"
                      icon="InfoIcon"
                      class="mr-25 info-icon"
                      @click="handleInfoIconE('extra', list.extra_fee_detail)"
                    />
                  </span>
                </li>
                <li v-if="Number(list.fumigation_fee) > 0">
                  <span>{{ $t('calculate.bambooFee') }}:</span>
                  <span>{{ currentCurrency.symbol }}{{ $money.format(Number(list.fumigation_fee).toFixed(2)) }}
                  </span>
                </li>
              </ul>
              <div class="single-parcel">
                <span class="fixed">{{ $t('calculate.limit') }}</span>
                <p>
                  <span>{{ $t('calculate.maximumWeight') }}</span>
                  <span v-show="Number(list.courier_limit.maximum_weight)">{{ Number(list.courier_limit.maximum_weight) }}{{weight_unit}}</span>
                  <span v-show="!Number(list.courier_limit.maximum_weight)">{{ $t('calculate.unrestricted') }}</span>
                </p>
                <p>
                  <span>{{ $t('calculate.maxChargeWeidht') }}</span>
                  <span v-show="Number(list.courier_limit.max_charge_weight)">{{ Number(list.courier_limit.max_charge_weight) }}{{weight_unit}}</span>
                  <span v-show="!Number(list.courier_limit.max_charge_weight)">{{ $t('calculate.unrestricted') }}</span>
                </p>
                <p>
                  <span>{{ $t('calculate.maxCube') }}</span>
                  <span v-show="Number(list.courier_limit.max_cube)">{{ Number(list.courier_limit.max_cube) }}{{weight_unit}}</span>
                  <span v-show="!Number(list.courier_limit.max_cube)">{{ $t('calculate.unrestricted') }}</span>
                </p>
                <p>
                  <span>{{ $t('calculate.longestEdge') }}</span>
                  <span v-show="Number(list.courier_limit.maximum_length)">{{ list.courier_limit.maximum_length }}{{length_unit}}</span>
                  <span v-show="!Number(list.courier_limit.maximum_length)">{{ $t('calculate.unrestricted') }}</span>
                </p>
                <p>
                  <span>{{ $t('calculate.notExceeding') }}
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-25 info-icon info-icon-s"
                      @click="handleInfoIconS()"
                    />
                  </span>
                  <span v-show="Number(list.courier_limit.girth)">{{ Number(list.courier_limit.girth) }}{{length_unit}}</span>
                  <span v-show="!Number(list.courier_limit.girth)">{{ $t('calculate.unrestricted') }}</span>
                </p>
              </div>
            </div>
          </b-col>
          <b-col
            xl="5"
            lg="12"
            md="12"
            sm="12"
          >
            <div class="single-parcel black-tx">
              <span class="fixed">{{ $t('calculate.distribution') }}</span>
              <div v-if="(pickUpList && pickUpList.length || !disDelivery) && (list.show_end_delivery == 1 || list.allow_agent == 1)">
                <div
                  v-if="list.allow_agent == 1"
                  class="delivery"
                  style="margin-top:30px"
                >
                  <b-form-radio
                    v-model="delivery"
                    plain
                    :disabled="pickUpList && !pickUpList.length"
                    :name="`delivery${idx}`"
                    value="1"
                  >
                    <span
                      v-if="list.id !== 51"
                      class="radio-text"
                    >{{ $t('calculate.pickUp') }}</span>
                    <span
                      v-else
                      class="radio-text"
                    >香港自提</span>
                  </b-form-radio>
                  <span
                    v-show="pickUpList && pickUpList.length"
                    v-b-modal:[`modal${idx}`]
                    class="click"
                  >{{ $t('calculate.choosePickUp') }}</span>
                </div>
                <div
                  v-if="list.show_end_delivery == 1"
                  class="delivery"
                >
                  <b-form-radio
                    v-model="delivery"
                    :disabled="disDelivery"
                    plain
                    :name="`delivery${idx}`"
                    value="2"
                  >
                    <span class="radio-text">{{ $t('calculate.delivery') }}（+{{ Number(estimation) > 0 ? currentCurrency.symbol : '' }}{{ Number(estimation) > 0 ? $money.format(Number(estimation)) : 'Loading...' }}）</span>
                  </b-form-radio>
                  <span
                    v-show="!disDelivery"
                    v-b-modal:[`modalDelivery${idx}`]
                    class="click"
                  >{{ $t('calculate.chooseDelivery') }}</span>
                </div>
              </div>
              <div
                v-else
                class="delivery"
              >
                <span class="radio-text">{{ $t('calculate.delivery') }}</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-footer>
      <b-card-footer class="d-flex justify-content-start align-content-center">
        <b-row
          style="margin:0; width: 100%;"
          class="black-tx"
          align-v="center"
        >
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="12"
          >
            <span>{{ $t('calculate.actualWeight') }}:
              <span>{{ list.dead_weight }}{{weight_unit}}</span>
            </span>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="12"
          >
            <span>{{ $t('calculate.volumeWeight') }}:
              <span>{{ list.v_weight }}{{weight_unit}}</span>
            </span>
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="12"
          >
            {{ $t('calculate.chargeableWeight') }}:{{ list.weight }}{{weight_unit}}
          </b-col>
          <b-col
            xl="3"
            lg="3"
            md="3"
            sm="12"
          >
            {{ $t('calculate.volume') }}:{{ Number(list.cube).toFixed(3) }}m<sup>3</sup>
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-toggle:[`collapse${idx}`]
          variant="outline-primary"
          style="margin-right: 100px;word-break: keep-all;"
        >
          {{ $t('calculate.singleChargeableWeight') }}
        </b-button>
      </b-card-footer>
    </b-card>
    <b-collapse
      :id="`collapse${idx}`"
      class="mt-2"
    >
      <b-card class="border mb-0">
        <b-table
          ref="selectableTable"
          selectable
          select-mode="single"
          :items="parcelList"
          :fields="fieldsParcel"
          responsive="sm"
        >
          <template #cell(size)="data">
            <span>{{ data.item.length }}{{length_unit}} * {{ data.item.width }}{{length_unit}} * {{ data.item.height }}{{length_unit}}</span>
          </template>
          <template #cell(dead_weight)="data">
            <span>{{ (Number(data.item.dead_weight)).toFixed(2) }}{{weight_unit}}</span>
          </template>
          <template #cell(volumetric_weight)="data">
            <span>{{ (Number(data.item.volumetric_weight)).toFixed(2) }}{{weight_unit}}</span>
          </template>
          <template #cell(cube)="data">
            <span>{{ (Number(data.item.cube)).toFixed(3) }}m<sup>3</sup></span>
          </template>
          <template #cell(chargeable_weight)="data">
            <span>{{ (Number(data.item.chargeable_weight)).toFixed(2) }}{{weight_unit}}</span>
          </template>
        </b-table>
      </b-card>
    </b-collapse>
    <b-modal
      :id="`modal${idx}`"
      v-model="pickUpShow"
      ok-only
      :ok-title="$t('common.close')"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="$t('calculate.pickUpSite')"
    >
      <b-table
        :items="pickUpList"
        :fields="fields"
        responsive="sm"
      >
        <template #cell(agent_name)="data">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <span>{{ data.item.agent_name }}</span>
              <span
                v-if="Number(deliveryCost) > 0 && data.item.id === 5110"
                class="delivery-cost"
              >({{ $t('calculate.deJust') }}{{ currentCurrency.symbol }}{{ $money.format(Number(deliveryCost)) }})</span>
            </div>
            <span
              v-if="Number(deliveryCost) > 0 && data.item.id === 5110"
              class="click"
              @click="handleChooseDe"
            >
              {{ $t('calculate.chooseDe') }}
            </span>
          </div>
        </template>
        <template #cell(address_all)="data">
          <span>{{ data.item.address }}, {{ data.item.city }}, {{ data.item.state }}, {{ data.item.postcode }}</span>
        </template>
      </b-table>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="md"
          class="float-right"
          @click="pickUpShow = false"
        >
          {{ $t('common.close') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      :id="`modalDelivery${idx}`"
      v-model="deliveryShow"
      ok-only
      :ok-title="$t('common.sure')"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="$t('calculate.localLogistics')"
    >
      <b-table
        ref="selectableTable"
        selectable
        select-mode="single"
        :items="deliveryList"
        :fields="deliveryFields"
        responsive="sm"
        @row-selected="onRowSelected"
      />
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="md"
          class="float-right"
          @click="handleDeSure"
        >
          {{ $t('common.sure') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BCardBody, BCardFooter, BFormRadio, BCollapse,
  BModal, BTable, VBToggle, VBModal, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import http from '@/libs/request'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardBody,
    BCardFooter,
    BFormRadio,
    BCollapse,
    BModal,
    BTable,
    BAlert,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    list: {
      type: Object,
      default: () => { },
    },
    idx: {
      type: Number,
      default: 1,
    },
    calc: {
      type: Object,
      default: () => { },
    },
    currentCurrency: {
      type: Object,
      default: () => { },
    },
    declare: {
      type: [Number, String],
      default: '',
    },
    isHasBamboo: {
      type: Boolean,
      default: false,
    },
    isChooseCm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      delivery: '1',
      fields: [],
      pickUpList: [],
      selected: [],
      disDelivery: false,
      deliveryFields: [],
      deliveryList: [],
      pickUpShow: false,
      deliveryShow: false,
      estimation: '0.00',
      totalEstimation: this.list.estimation,
      fieldsParcel: [],
      parcelList: this.list.parcels,
      deliveryCost: '',
      declarePrice: '',
      bambooAlert: '',
      weight_unit: 'kg',
      length_unit: 'cm',
    }
  },
  watch: {
    delivery(val) {
      if (val === '2') {
        this.totalEstimation = (Number(this.list.estimation) + Number(this.estimation)).toFixed(2)
      } else {
        this.totalEstimation = this.list.estimation
      }
    },
    pickUpShow(val) {
      if (val) {
        this.handleSeti18n()
      }
    },
    deliveryShow(val) {
      if (val) {
        this.handleSeti18n()
      }
    },
    declare: {
      handler(val) {
        if (Number(val) > 0) {
          this.declarePrice = val
        }
      },
      immediate: true,
    },
  },
  created() {
    this.weight_unit = this.isChooseCm ? 'kg' : 'lb'
    this.length_unit = this.isChooseCm ? 'cm' : 'in'
    this.calcEndCost()
    this.CalcAvailableAgents()
    this.handleSeti18n()
  },
  methods: {
    handleLink() {
      window.open(this.list.link)
    },
    handleInfoIconTotal(list) {
      let arr = []
      if (list.extra_fee_detail && list.extra_fee_detail.length > 0) {
        list.extra_fee_detail.forEach(item => {
          arr.push({ name: item.name, amount: item.fee || item.amount })
        })
      }
      if (list.fullfillment_fee_detail && list.fullfillment_fee_detail.length > 0) {
        list.fullfillment_fee_detail.forEach(item => {
          arr.push({ name: item.name, amount: item.fee || item.amount })
        })
      }
      if (list.tax_fee_detail && list.tax_fee_detail.length > 0) {
        list.tax_fee_detail.forEach(item => {
          arr.push({ name: item.name, amount: item.amount })
        })
      }
      if (list.estimation_detail && list.estimation_detail.length > 0) {
        list.estimation_detail.forEach(item => {
          arr.push({ name: item.desc, amount: item.amount })
        })
      }
      if (Number(list.fumigation_fee) > 0) {
        arr.push({ name: this.$t('calculate.bambooFee'), amount: list.fumigation_fee })
      }
      arr = arr.filter(item => Number(item.amount) > 0)
      const isMobile = this.isMobileDevice()
      let html = ''
      arr.forEach(item => {
        html += `
            <div style="color: #888585;align-items: center;line-height:34px;font-size:18px;display:flex;justify-content: center;">
              <span class="info-desc" style="display:block;min-width:${isMobile ? '37%' : '67%'};text-align:left;">${item.name}:</span>
              <span style="display:block;margin-left:10px;min-width: ${isMobile ? '63%' : '33%'};text-align:right;">${this.currentCurrency.symbol}${this.$money.format(Number(item.amount).toFixed(2))}</span>
            </div>
          `
      })
      html += `
        <div style="align-items: center;font-size:22px;display:flex;justify-content: center;${arr.length > 0 ? 'border-top: 1px solid #ffbb06;' : ''}padding-top:10px;margin-bottom:20px;margin-top: 5px;">
          <span class="info-desc" style="display:block;min-width:${isMobile ? '37%' : '67%'};text-align:left;">${this.$t('calculate.freight')}:</span>
          <span style="display:block;margin-left:10px;min-width: ${isMobile ? '63%' : '33%'};text-align:right;">${this.currentCurrency.symbol}${this.$money.format(Number(this.totalEstimation).toFixed(2))}</span>
        </div>
      `
      this.$swal({
        html,
        width: 500,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    isMobileDevice() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    handleNotUseIcon() {
      this.$swal({
        html: `<span style="font-size:22px">${this.list.unableSelectReason}</span>`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleSeti18n() {
      const pickUpSiteName = this.$t('calculate.pickUpSiteName')
      const address = this.$t('common.address')
      const logisticsName = this.$t('calculate.logisticsName')
      const deliveryFee = this.$t('calculate.deliveryFee')
      const actualWeight = this.$t('calculate.actualWeight')
      const volumeWeight = this.$t('calculate.volumeWeight')
      const chargeableWeight = this.$t('calculate.chargeableWeight')
      const size = this.$t('common.size')
      const volume = this.$t('calculate.volume')

      this.fields = [{ key: 'agent_name', label: pickUpSiteName }, { key: 'address_all', label: address }]
      this.deliveryFields = [{ key: 'courier_name', label: logisticsName }, { key: 'estimation', label: deliveryFee }]
      this.fieldsParcel = [{ key: 'dead_weight', label: actualWeight }, { key: 'volumetric_weight', label: volumeWeight }, { key: 'cube', label: volume }, { key: 'chargeable_weight', label: chargeableWeight }, { key: 'size', label: size }]
    },
    async calcEndCost() {
      const params = {
        currency_id: this.currentCurrency.id,
        courier_id: this.list.id,
        ...this.calc,
        weight_unit: this.weight_unit,
        length_unit: this.length_unit,
      }
      try {
        const data = await http.post('/api/calcEndCost', params)
        if (data.code === 422) {
          this.disDelivery = true
          if (this.pickUpList && !this.pickUpList.length && !this.disDelivery) this.delivery = '2'
        }
        if (data.code === 200) {
          this.disDelivery = false
          this.deliveryList = data.data.sort((a, b) => Number(a.estimation) - Number(b.estimation))
          this.estimation = this.deliveryList[0].estimation
        }
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    async CalcAvailableAgents () {
      const params = {
        courier_id: this.list.id,
        package: this.calc.package,
        postcode: this.calc.postcode
      }
      try {
        const { data } = await http.post('/api/CalcAvailableAgents', params)
        this.pickUpList = data.drop
        this.deliveryCost = data.cost
        if (this.pickUpList && !this.pickUpList.length && !this.disDelivery) this.delivery = '2'
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    onRowSelected (items) {
      this.selected = items
      this.estimation = items[0] ? items[0].estimation : '0.00'
      if (this.delivery === '2') {
        this.totalEstimation = (Number(this.list.estimation) + Number(this.estimation)).toFixed(2)
      } else {
        this.totalEstimation = this.list.estimation
      }
    },
    handleInfoIcon (desc) {
      this.$swal({
        html: `<span style="font-size:22px">${desc}</span>`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleInfoIconS () {
      this.$swal({
        html: `<span style="font-size:22px">${this.$t('calculate.girth')}</span>`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    handleInfoIconE(type,taxDetail){
      const detail = [].concat(taxDetail)
      let html = ''
      if (detail.length) {
        detail.forEach(item => {
          html += `
            <div style="align-items: center;line-height:34px;font-size:22px;display:flex;justify-content: center;">
              <span class="info-desc" style="display:block;min-width:${type === 'extra' ? '50%' : '67%'};text-align:right;">${item.desc || item.name}:</span>
              <span style="display:block;margin-left:10px;min-width: ${type === 'extra' ? '50%' : '33%'};text-align:left;">${this.currentCurrency.symbol}${this.$money.format(Number(item.amount || item.fee).toFixed(2))}</span>
            </div>
          `
        })
      }
      this.$swal({
        html,
        width: 700,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        size: "lg"
      })
    },
    handleChooseDe () {
      this.pickUpShow = false
      this.deliveryShow = true
    },
    handleDeSure () {
      this.delivery = '2'
      this.deliveryShow = false
    }
  },
}
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
.transport {
  color: #ffbb06;
  margin-top: 10px;
  span:nth-child(1),span:nth-child(2) {
    display: block;
    padding: 4px 8px;
    border: 1px solid #ffbb06;
    border-radius: 4px;
  }
  span:nth-child(2) {
    border: 1px solid #28c76f;
    color: #28c76f;
  }
}
.info-icon,
.info-icon-s {
  color: #ffbb06;
  position: relative;
  top: -2px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.info-icon-s {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.delivery-cost {
  font-size: 14px;
  margin-left: 5px;
}
.red-icon {
  color: red;
}
.line-intro{
  color: #ffbb06;
  display: block;
  margin-top: 50%;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
.mt-40{
  margin-top: 30%;
}

.logistics-tag{
  padding: 0 10px;
  margin-left: 20px;
  border-radius: 10px;
  font-size: 14px;
}

.error-tag{
  background-color: #dd524d;
}


@media screen and (max-width: 1200px)  {
  .single-parcel{
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 992px) {
  .btn {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .logistics {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .logistics-tag,
  .transport {
    width: 100%;
  }
  .result-title-name{
    display: initial !important;
  }
  .logistics-tag{
    margin-left: 0;
    margin: 10px 0;
  }
  .price{
    font-size: 18px;
  }
  .result-title{
    font-size: 22px;
  }
  .line-intro{
    margin-top: 10%;
  }
}
</style>