<template>
  <div>
    <LogoHeader />
    <img
      class="banner"
      src="../assets/images/pages/calculate-banner.png"
      alt="banner"
    >
    <div class="freight-container">
      <b-card
        class="mb-2"
      >
        <h1 class="welcome">
          {{ $t('calculate.title') }}
        </h1>
        <div class="loc-container d-flex justify-content-end align-items-center">
          <b-nav-item-dropdown
            id="dropdown-grouped"
            variant="link"
            class="dropdown-language"
            right
          >
            <template #button-content>
              <b-img
                :src="currentLocale.img"
                height="22px"
                width="22px"
                style="vertical-align:-5px"
                :alt="currentLocale.locale"
              />
              <span class="ml-50 text-body">{{ currentLocale.name }}</span>
            </template>
            <b-dropdown-item
              v-for="localeObj in locales"
              :key="localeObj.locale"
              @click="$i18n.locale = localeObj.locale"
            >
              <b-img
                :src="localeObj.img"
                height="22px"
                width="22px"
                style="vertical-align:-5px"
                :alt="localeObj.locale"
              />
              <span
                class="ml-50"
                style="font-size:16px"
              >{{ localeObj.name }}</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
        <h2 class="from-text mt-30">
          {{ $t('calculate.fromTitle') }}
        </h2>
        <p class="from-note">{{ $t('calculate.fromSubTitle') }} <a
          class="ware"
          target="_blank"
          :href="currentLocale.locale === 'cn' ? 'https://ebaoguo.com/ebaoguo-warehouse-location/' : currentLocale.locale === 'zh' ? 'https://ebaoguo.com/zh-hant/ebaoguo-warehouse-location/' : 'https://ebaoguo.com/en/ebaoguo-warehouse-location/'"
        >{{ $t('calculate.warehouse') }}</a>
        </p>
        <validation-observer
          ref="simpleRules"
          class="form"
        >
          <b-form>
            <b-row>
              <b-col
                xl="3"
                lg="6"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="store"
                    rules="required"
                  >
                    <v-select
                      v-model="form.store_id"
                      :style="errors[0] ? {border:'1px solid red',borderRadius:'0.357rem'} : {}"
                      :placeholder="$t('calculate.deliveryWarehouse')"
                      :state="errors.length > 0 ? false:null"
                      :options="storeList"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-overlay
              :show="overlayShow"
              rounded="sm"
              spinner-variant="primary"
              spinner-type="grow"
            >
              <b-row
                align-h="start"
                align-v="center"
                style="margin-top:20px"
              >
                <b-col
                  v-for="item in storeList"
                  :key="item.store_id"
                  xl="4"
                  lg="6"
                  md="12"
                  sm="12"
                  style="margin-top:20px"
                >
                  <div
                    :class="form.store_id && form.store_id.store_id === item.store_id ? 'store-card active-card' : 'store-card'"
                    @click="handleClickStoreCard(item)"
                  >
                    <img
                      :src="item.country_logo"
                      alt="store-img"
                    >
                    <div class="store-card-left">
                      <h4>
                        {{ item.name }}
                      </h4>
                      <p>
                        <span v-if="item.store_id !== 2">{{ item.city }}</span>
                        <span v-else>{{ item.city.split('/')[0] }}</span>
                        <span>{{ item.postcode }}</span>
                      </p>
                      <p style="margin-top:2px">
                        <span>{{ item.state }}</span>
                        <span>{{ item.country }}</span>
                      </p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
            <hr class="hr">
            <h2
              class="from-text mt-30"
            >
              {{ $t('calculate.sendTitle') }}
            </h2>
            <b-row>
              <b-col
                xl="3"
                lg="6"
                md="12"
                sm="12"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="country"
                    rules="required"
                  >
                    <v-select
                      v-model="form.country_id"
                      :style="errors[0] ? {border:'1px solid red',borderRadius:'0.357rem'} : {}"
                      :placeholder="$t('calculate.chooseCountry')"
                      :state="errors.length > 0 ? false:null"
                      :options="countryList"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- 非联动 -->
              <b-col
                v-if="isLinkage"
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="postcode"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.postcode"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('calculate.postcode')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- 联动 -->
              <b-col
                v-if="isDisabledForm"
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="postcode"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.postcode"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('calculate.postcode')"
                      @change="handlePostcode"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isDisabledForm"
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="suburb"
                    rules="required"
                  >
                    <v-select
                      v-model="form.suburb"
                      :style="errors[0] ? {border:'1px solid red',borderRadius:'0.357rem'} : {}"
                      :placeholder="$t('calculate.suburb')"
                      :state="errors.length > 0 ? false:null"
                      :options="suburbList"
                      :disabled="isDisabledForm && suburbList.length === 0"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="isNoCityShow"
                      class="text-danger"
                      style="font-size:14px"
                    >{{ $t('calculate.noCity') }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isDisabledForm"
                md="3"
              >
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="state"
                    rules="required"
                  >
                    <b-form-input
                      v-model="form.state"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('calculate.state')"
                      :disabled="isDisabledForm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr
              v-if="!isLocal"
              class="hr"
            >
            <h2
              v-if="!isLocal"
              class="from-text mt-30"
            >
              {{ $t('calculate.declare') }}{{ form.store_id.store_id === 13 ? '' : `(${$t('calculate.optional')})` }}
            </h2>
            <b-row v-if="!isLocal">
              <b-col
                xl="3"
                lg="6"
                md="12"
                sm="12"
              >
                <b-input-group
                  v-if="form.store_id.store_id !== 13"
                  :prepend="declareCurrency"
                >
                  <b-form-input
                    v-model="form.declare"
                    :placeholder="$t('calculate.inputDeclare')"
                    type="number"
                    @blur="handleDeclare"
                  />
                </b-input-group>
                <b-form-group v-if="form.store_id.store_id === 13">
                  <validation-provider
                    #default="{ errors }"
                    name="declare"
                    rules="required"
                  >
                    <b-input-group
                      :prepend="declareCurrency"
                    >
                      <b-form-input
                        v-model="form.declare"
                        :placeholder="$t('calculate.inputDeclare')"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                        @blur="handleDeclare"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="hr">
            <div class="d-flex justify-content-between align-items-center mt-30">
              <h2
                class="from-text"
              >
                {{ $t('calculate.parcelInfo') }}
              </h2>
            </div>
          </b-form>
        </validation-observer>
        <parcel-repeat-form
          ref="repeatForm"
          :tags="tagsList"
          :isChooseCm="isChooseCm"
          @handleGetPackages="handleGetPackages"
        />
        <b-row style="margin-top:80px">
          <b-col
            cols="12"
            style="text-align:center;"
          >
            <b-button
              variant="relief-primary"
              type="submit"
              size="lg"
              @click.prevent="validationForm"
            >
              {{ $t('calculate.carriage') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-alert
          variant="danger"
          show
        >
          <div class="alert-body">
            <span>{{ $t('calculate.resultAlert') }}</span>
          </div>
        </b-alert>
        <b-row
          v-show="isLoading"
          style="margin:0;padding:30px 0"
        >
          <b-col
            cols="12"
            style="display:flex;align-items:center;justify-content:center"
          >
            <b-spinner
              style="width: 2rem; height: 2rem;"
              label="Large Spinner"
              type="grow"
              variant="primary"
            />
            <span class="loading-text">{{ $t('calculate.loading') }}</span>
          </b-col>
        </b-row>
        <p
          v-show="empty"
          class="empty"
        >
          {{ $t('calculate.noChoose') }}
        </p>
        <div
          v-show="resultList.length"
          class="block"
        />
        <div
          v-if="resultList.length"
          class="d-flex justify-content-end"
        >
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="`${$t('calculate.currentCurrrency')}: ${currentCurrency.currency}(${currentCurrency.symbol})`"
            variant="flat-primary"
          >
            <b-dropdown-item
              v-for="item in currencyList"
              :key="item.id"
              @click="currentCurrency = item"
            >
              {{ item.currency }}({{ item.symbol }})
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <freight-result
          v-for="(item,idx) in resultList"
          :key="item.id"
          :list="item"
          :is-has-bamboo="isHasBamboo"
          :idx="idx"
          :calc="calc"
          :declare="form.declare"
          :current-currency="currentCurrency"
          :isChooseCm="isChooseCm"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BFormGroup, BRow, BCol, BForm, BButton, BInputGroup, BFormInput, BSpinner, BAlert, BOverlay, BNavItemDropdown, BDropdownItem, BImg, BDropdown, BButtonGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import http from '@/libs/request'
import Ripple from 'vue-ripple-directive'
import LogoHeader from '../layouts/components/LogoHeader.vue'
import ParcelRepeatForm from '../layouts/components/ParcelRepeatForm.vue'
import FreightResult from './FreightResult.vue'

export default {
  components: {
    LogoHeader,
    BCard,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    ParcelRepeatForm,
    BSpinner,
    BAlert,
    FreightResult,
    BOverlay,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BDropdown,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        store_id: '',
        country_id: '',
        postcode: '',
        suburb: '',
        state: '',
        package: [],
        declare: '',
      },
      currentCurrency: {
        currency: 'AUD',
        symbol: 'AU$',
        id: 3,
      },
      currencyList: [],
      storeList: [],
      countryList: [],
      suburbList: [],
      stateList: [],
      tagsList: [],
      required,
      isLoading: false,
      isDisabledForm: false,
      isLinkage: false,
      resultList: [],
      overlayShow: true,
      calc: {},
      empty: false,
      isTW: false,
      isNoCityShow: false,
      isHasBamboo: false,
      isChooseCm: true,
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    declareCurrency({ form }) {
      return form.store_id.currency_name
    },
    isLocal({ form }) {
      /* eslint-disable */
      if (form.store_id.store_id === 1 && form.country_id.id === 13) {
        return true
      } else if (form.store_id.store_id === 2 && form.country_id.id === 44) {
        return true
      } else if (form.store_id.store_id === 3 && form.country_id.id === 132) {
        return true
      } else if (form.store_id.store_id === 5 && form.country_id.id === 214) {
        return true
      } else if (form.store_id.store_id === 8 && form.country_id.id === 109) {
        return true
      } else if (form.store_id.store_id === 11 && form.country_id.id === 98) {
        return true
      } else if (form.store_id.store_id === 13 && form.country_id.id === 157) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'form.store_id': {
      handler(val) {
        if (!val) {
          return
        }
        this.isNoCityShow = false
        this.initCountryState(val)
        this.initTagsState(val)
        this.form.postcode = ''
        this.form.suburb = ''
        this.form.state = ''
        this.form.country_id = ''
        this.form.declare = ''
        this.resultList = []
        this.$refs.simpleRules.reset()
        this.handleCurrency(val)
      },
      deep: true,
    },
    'form.country_id': {
      handler(val) {
        if (!val) {
          return
        }
        this.isNoCityShow = false
        this.form.postcode = ''
        this.form.suburb = ''
        this.form.state = ''
        this.suburbList = []
        this.stateList = []
        this.form.declare = ''
        if (val.id === 13 || val.id === 132 || val.id === 157 || val.id === 214) {
          this.isDisabledForm = true
          this.isLinkage = false
        } else {
          this.isDisabledForm = false
          if (val.id !== 98 && val.id !== 128) {
            this.isLinkage = true
          } else {
            this.isLinkage = false
          }
        }
        this.$refs.simpleRules.reset()
      },
    },
    'currentLocale.locale': {
      handler() {
        const text = this.$t('calculate.chooseProperty')
        this.tagsList.splice(0, 1, { value: null, text })
        this.initState()
        this.handleSubmit()
        this.initTagsState(this.form.store_id)
      },
    },
    'form.suburb': {
      handler(val) {
        if (val && this.form.country_id.id !== 214) {
          this.form.state = val.state
        }
      },
    },
    'form.state': {
      handler(val) {
        if (val && this.form.country_id.id === 214) {
          this.handleGetTWSuburb()
        }
      },
    },
    currentCurrency() {
      this.handleSubmit()
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'cn',
        img: 'https://s3-ap-southeast-2.amazonaws.com/ebaoguo.com/flags/china.png',
        name: '简体中文',
        browser: 'zh-CN'
      },
      {
        locale: 'en',
        img: 'https://s3-ap-southeast-2.amazonaws.com/ebaoguo.com/flags/australia.png',
        name: 'English',
        browser: 'en'
      },
      {
        locale: 'zh',
        img: 'https://s3.ap-southeast-2.amazonaws.com/ebaoguo.com/flags/taiwan.png',
        name: '繁體中文',
        browser: 'zh-TW'
      },
    ]
    /* eslint-disable global-require */
    return {
      locales,
    }
  },
  created() {
    this.getUserLanguage()
    this.initState()
    this.initCurrencyList()
    this.tagsList.push({ value: null, text: this.$t('calculate.chooseProperty') })
    jQuery(document).on("SBReady", function () {
      SBChat.initChat();
    });
  },
  methods: {
    getUserLanguage() {
      const userLanguage = navigator.language || navigator.userLanguage;
      this.$i18n.locale = userLanguage !== 'zh-CN' && userLanguage !== 'zh-TW' ? 'en' : this.locales.find(item => item.browser === userLanguage).locale
    },
    handleCurrency(val) {
      const currencyObj = this.currencyList.find(item => item.id === val.currency_id)
      this.currentCurrency = {
        currency: currencyObj.currency,
        symbol: currencyObj.symbol,
        id: currencyObj.id,
      }
    },
    handleDeclare() {
      if (Number(this.form.declare) > 0) {
        this.form.declare = Number(this.form.declare).toFixed(2)
      }
    },
    async initState() {
      try {
        this.overlayShow = true
        const { data } = await http.get(`/api/getWarehouseAddress?language=${this.currentLocale.locale}`)
        this.overlayShow = false
        this.storeList = data.map(item => ({
          label: item.name,
          value: item.store_id,
          ...item,
        }))
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    async initCurrencyList(){
      try {
        const {data} = await http.get(`/api/currencyList`)
        this.currencyList = data
      } catch (error) {
        /* eslint-disable */
        console.log('error',error);
      }
    },
    async initCountryState(val) {
      try {
        const { data } = await http.get(`/api/availableCountry/${val.store_id}`)
        this.countryList = data.map(item => ({
          label: item.country,
          value: item.id,
          ...item,
        }))
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    async initTagsState(val) {
      try {
        const { data } = await http.get('/api/tagList', { store_id: val.store_id,language: this.$i18n.locale})
        let arr = []
        arr = data.map(item => ({
          text: item.name,
          value: item.tags_id,
        }))
        arr.unshift({ value: null, text: this.$t('calculate.chooseProperty') })
        this.tagsList = arr
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    async handleGetState(){
      try {
        const { data } = await http.get('/api/stateList',{ page: 1, perPage: 1000, country_id: 214 })
        this.stateList = data.map(item=>({
          label:item.name,
          value:item.id
        }))
      } catch (error) {
        console.log('error', error);
      }
    },
    async handleGetTWSuburb(){
      try {
        const { data } = await http.get('/api/autoPostcodeSuburb', { country_id: 214, state_id: this.form.state.value })
        this.suburbList = data.map(item => ({
          label: item.name,
          value: item.name,
          ...item,
        }))
      } catch (error) {
        console.log('error', error);
      }
    },
    async handleSubmit() {
      this.empty = false
      try {
        const params = {
          area: '0',
          country_id: this.form.country_id.id,
          country_id_from: this.form.store_id.country_id,
          package: this.form.package.map(item => ({ ...item.form })),
          postcode: this.form.postcode,
          state: this.form.country_id.id === 214 ? this.form.state.value : this.form.suburb.value,
          store_id: this.form.store_id.store_id,
          suburb: this.form.suburb.label,
          language:this.currentLocale.locale,
          declare_value:this.form.declare,
          currency_id:this.currentCurrency.id,
          weight_unit:this.isChooseCm ? 'kg' : 'lb',
          length_unit:this.isChooseCm ? 'cm' : 'in',
        }
        this.calc = {
          package: this.form.package.map(item => ({ ...item.form })),
          country_id: this.form.country_id.id,
          postcode: this.form.postcode,
          state: this.form.suburb.value,
          suburb: this.form.suburb.label,
        }
        this.isHasBamboo = this.form.package.some(item => {
          return item.form.tags_ids === 76
        })
        this.resultList = []
        this.isLoading = true
        const { data } = await http.post('/api/getQuotation', params)
        if(!data.length) {
          this.empty = true
        } else {
          this.empty = false
        }
        this.isLoading = false

        let canUse = [].concat(data)
        let notUse = [].concat(data)
        canUse = canUse.filter(item => Number(item.courier_limit.max_declare_amount) === 0 ||  Number(item.courier_limit.max_declare_amount) >= Number(this.form.declare))
        notUse = notUse.filter(item => Number(item.courier_limit.max_declare_amount) !== 0 &&  Number(item.courier_limit.max_declare_amount) < Number(this.form.declare))
        canUse = canUse.sort((a, b) => Number(a.estimation) - Number(b.estimation))
        const arr = [...canUse,...notUse]
        this.resultList = arr
      } catch (error) {
        /* eslint-disable */
        console.log('error', error)
      }
    },
    async validationForm() {
      const isValid = await this.$refs.simpleRules.validate()
      const isValidForm = await this.$refs.repeatForm.validationForm()
      if(!isValidForm || !isValid) return
      if(this.form.country_id.id !== 13 && this.form.country_id.id !== 132 && this.form.country_id.id !== 157 && this.form.country_id.id !== 214) {
        if(this.form.store_id && this.form.package.length) {
          this.handleSubmit()
        }
      }else {
        this.$refs.simpleRules.validate().then(async success => {
          if (success && this.form.package.length) {
            this.handleSubmit()
          }
        })
      }
    },
    handleClickStoreCard(item) {
      if(item.weight_unit === 'kg') {
        this.isChooseCm = true
      } else {
        this.isChooseCm = false
      }
      this.form.store_id = item
    },
    async handlePostcode(val) {
      if(!val) {
        this.suburbList = []
        this.stateList = []
        this.form.suburb = ''
        this.form.state = ''
        return
      }
      if (!this.form.country_id) {
        return
      }
      if (this.form.country_id.id === 13 || this.form.country_id.id === 132 || this.form.country_id.id === 157 || this.form.country_id.id === 214) {
        try {
          const { data } = await http.get('/api/autoPostcodeSuburb', { country_id: this.form.country_id.id, postcode: val })
          if(data.length) {
            this.isNoCityShow = false
            this.suburbList = data.map(item => ({
              label: item.name,
              value: item.state_id,
              ...item,
            }))
            this.form.state = data[0].state
            /* eslint-disable */
            this.form.suburb = this.suburbList[0]
          } else {
            this.isNoCityShow = true
            this.suburbList = []
            this.form.state = ''
            this.form.suburb = ''
          }
        } catch (error) {
          /* eslint-disable */
          console.log('error', error)
        }
        console.log('val', val)
      }
    },
    handleGetPackages(val) {
      this.form.package = val
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.banner{
  width: 100%;
  display: block;
}
.freight-container{
  width: 70%;
  margin: 0 auto;
  padding: 30px 0;
}
h1,h2,h4{
  font-weight: normal;
}
.loc-container{
  display: flex;
  justify-content: flex-end;
  list-style: none;
}
.welcome{
  font-size: 36px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  letter-spacing: 2px;
}
.mt-30{
  margin-top: 30px;
}
.from-text{
  font-size: 28px;
  letter-spacing: 1px;
}
.from-note{
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 26px;
  margin-top: 20px;
  color: #999;
}
.form{
  margin-top: 30px;
}
.store-card,.active-card{
  border: 1px solid #e4e0e0;
  padding: 15px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  img{
    display: block;
    width: 70px;
    height: 70px;
  }
  .store-card-left{
    margin-left: 40px;
  }
  h4{
    font-size: 24px;
    letter-spacing: 1px;
  }
  p{
    margin: 0;
  }
  span{
    display: inline-block;
    margin-right: 10px;
    font-size: 18px;
  }
}
.active-card{
  border: 1px solid $primary;
  background: $primary;
  color: rgba($color: #fff, $alpha: .9);
  h4{
    color: rgba($color: #fff, $alpha: .9);
  }
}
.row{
  margin-top:40px
}
.hr{
  border-top: 1px dashed #aaa;
  margin: 60px 0 50px 0;
}
.text-danger{
  font-size: 16px;
}
.loading-text{
  font-size: 22px;
  display: inline-block;
  margin-left: 20px;
}
.result-title{
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.price{
  font-size: 20px;
}
.black-tx{
  color: #333;
}
.logistics{
  text-align: center;
}
.logistics-logo{
  display: inline-block;
  height: 120px;
}
.logistics-tag{
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background: green;
  display: block;
}
.logistics-ul{
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 30px;
  li{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    &:not(:last-child){
      margin-bottom: 8px;
    }
    span:nth-child(1){
      display: block;
      width: 42%;
      text-align: right;
    }
    span:nth-child(2){
      width: 58%;
      text-align: left;
      margin-left: 10px;
    }
    .tax-note{
      display: inline !important;
    }
  }
}
.single-parcel{
  width:100%;
  height: 100%;
  border: 1px solid #d5d5d5;
  padding: 25px 15px;
  position: relative;
  p{
    margin: 0;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    line-height: 24px;
  }
}
.fixed{
  position: absolute;
  top:-10px;
  font-size: 14px;
  z-index: 3;
  background: #fff;
  padding: 0 6px;
}
.radio-text{
  font-size: 16px;
}
.form-check{
  display: flex;
  align-items: center;
}
.form-check-input{
  margin-top: 0;
}
.delivery{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.click,.ware{
  font-size: 14px;
  text-decoration: underline;
  color: #0095eb;
  cursor: pointer;
}
.ware{
  font-size: 16px;
  &:hover{
    text-decoration: underline;
  }
}
.modal-lg, .modal-xl{
  max-width: 1000px;
}
.block{
  width: 100%;
  height: 20px;
}
.empty{
  font-size: 22px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
}
@media screen and (max-width: 1740px) {
 .freight-container{
    width:85%;
  }
}
@media screen and (max-width: 1730px) {
  .store-card{
    padding: 10px 10px;
  }
  .store-card-left{
    margin-left: 20px !important;
  }
}
@media screen and (max-width: 1400px) {
  .freight-container{
    width:98%;
  }
}
@media screen and (max-width: 1214px) {
  .store-card{
    padding: 10px 6px;
  }
}
@media screen and (max-width: 1200px) {
  .store-card{
    padding: 15px 20px;
  }
}
@media screen and (max-width: 800px) {
  .freight-container{
    width:100%;
    padding: 0;
  }
}
</style>
<style scoped>
.freight-container ::v-deep .card-body{
  padding: 1.5rem 2.5rem;
}
.freight-container ::v-deep .vs__dropdown-toggle{
  height: 2.714rem;
}
.freight-container ::v-deep [dir] .vs__search, [dir] .vs__search:focus{
  margin: 2px 0 0 0;
}
.freight-container ::v-deep .v-select.vs--single .vs__selected{
  margin-top: 3px !important;
}
.freight-container ::v-deep .b-nav-dropdown .dropdown-toggle::after{
  background-image: none !important;
}
.freight-container ::v-deep .border-secondary{
  border: 1px solid #afafb0 !important;
}
.freight-container ::v-deep .dropdown .btn{
  font-size: 1.4rem;
}
</style>
